import React, { useState, useRef, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Container, ColumnSocialMidiaDescktop } from './styles';
import { SocialIcon } from 'react-social-icons';
import googleAnalistics from "../../tools/GoogleAnalytics";

export default function Header() {

  const linkGrupoWhatsApp = 'https://chat.whatsapp.com/Kxra4l33KhZ0ITbLCzjiwj'
  const linkGrupoTelegram = 'https://t.me/+6v-QXVuQWwZiMmUx'

  const history = useHistory();

  const [input, setInput] = useState("");
  const [barOpened, setBarOpened] = useState(true);
  const formRef = useRef();
  const inputFocus = useRef();

  const [analitcs, setAnalitcs] = useState(false);
  useEffect(() => {
    async function gerarAnalitics() {
      if (!analitcs) {
        setAnalitcs(true)
        googleAnalistics(history);
        await sleep(2000);
        setAnalitcs(false)
      }
    }
    gerarAnalitics();
  })

  async function sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const onFormSubmit = e => {
    redir(input)
    e.preventDefault();
    setInput("");
    setBarOpened(true);

    return;
  };

  async function redir(input) {
    window.open(`http://${window.location.hostname === 'localhost' ? 'localhost:3100' : window.location.hostname}/search/${input}`, "_self");
  }

  function handleRouter(route) {
    history.push(route);
  };

  function handleHome(route) {
    window.open(`http://${window.location.hostname === 'localhost' ? 'localhost:3100' : window.location.hostname}/`, "_self");
  };

  function handleWatsApp(short, name) {
    window.open(`https://chat.whatsapp.com/Kxra4l33KhZ0ITbLCzjiwj`, '_blank');
  }

  function handleTelegram(short, name) {
    window.open(`https://t.me/ofertabesttt`, '_blank');
  }

  function handleInstagram(short, name) {
    window.open(`https://www.instagram.com/monteirotech0`, '_blank');
  }

  function handleYoutube(short, name) {
    window.open(`https://youtube.com/@MonteiroTech`, '_blank');
  }

  function handleFacboock(short, name) {
    window.open(`https://www.facebook.com/monteirotech0`, '_blank');
  }

  function handleTikTok(short, name) {
    window.open(`https://www.tiktok.com/@monteirotech_?_t=8jHRICfIJPi&_r=1`, '_blank');
  }

  return (
    <>
      <Container>
        <ColumnSocialMidiaDescktop target="_blanck">
          <button type="button" onClick={() => handleHome()}>
             <img src="https://i.pinimg.com/736x/1a/8b/ba/1a8bba39f4c4d852f106d8e12afa085b.jpg" alt="OfertaBest" className="imgOfertabest" />
            
          </button>
        </ColumnSocialMidiaDescktop>
      
      </Container>

      <>

      
      

        { window.location.pathname !== '/telegram' &&  window.location.pathname !== '/whatsapp' ? 

        <Container style={{ alignItems: 'center', justifyContent: 'center', padding: '20px 20px' }}>
          <div style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center', alignContent: "flex" , color: '#fff', fontSize: '22px'}}>
            <h3>Grupo de Ofertas</h3>
          </div>
        </Container>
        
        
        : ''}

        { window.location.pathname !== '/telegram' &&  window.location.pathname !== '/whatsapp' ? 

                
          <>
            <Container style={{ alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center', alignContent: "flex" }}>
                <a href={linkGrupoWhatsApp} target="_blank" className="botao" style={{ padding: '20px 20px', backgroundColor: '#25D366', color: '#fff', fontSize: '22px', borderRadius: '8px', textDecoration: 'none', cursor: 'pointer', marginRight: '5px', marginLeft: '5px', marginBottom: '100px', }}>
                  <SocialIcon network="whatsapp" bgColor="#0000" fgColor="white" style={{ height: 40, width: 40 }} />
                  WhatsApp
                </a>
              </div>
            </Container>

            <ColumnSocialMidiaDescktop target="_blanck" style= {{justifyContent: 'center'}}>
              <button type="button" style={{backgroundColor: '#15171a'}}>
              
              </button>
            </ColumnSocialMidiaDescktop>
            <ColumnSocialMidiaDescktop target="_blanck" style= {{justifyContent: 'center'}}>
              <button type="button" style={{backgroundColor: '#15171a'}}>
              
              </button>
            </ColumnSocialMidiaDescktop>  

            <ColumnSocialMidiaDescktop target="_blanck" style= {{justifyContent: 'center'}}>
              <button type="button" style={{backgroundColor: '#15171a'}}>
              
              </button>
            </ColumnSocialMidiaDescktop>  

            <ColumnSocialMidiaDescktop target="_blanck" style= {{justifyContent: 'center'}}>
              <button type="button" style={{backgroundColor: '#15171a'}}>
              
              </button>
            </ColumnSocialMidiaDescktop>  

            <Container style={{ alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center', alignContent: "flex" }}>
                <a href={linkGrupoTelegram} target="_blank" className="botao" style={{ padding: '20px 20px', backgroundColor: '#229ED9', color: '#fff', fontSize: '22px', borderRadius: '8px', textDecoration: 'none', cursor: 'pointer', marginRight: '5px', marginLeft: '5px', marginBottom: '100px', }}>
                  <SocialIcon network="telegram" bgColor="#0000" fgColor="white" style={{ height: 40, width: 40 }} />
                  Telegram
                </a>
              </div>
            </Container>
          </>   

        : ''}

        { window.location.pathname !== '/telegram' &&  window.location.pathname !== '/whatsapp' &&
           !window.location.pathname.includes('/oferta')  &&  !window.location.pathname.includes('/promo') &&
           !window.location.pathname.includes('/lk') ? 

          <>
            <ColumnSocialMidiaDescktop target="_blanck" style= {{justifyContent: 'center'}}>
              <button type="button" style={{backgroundColor: '#15171a'}}>
              
              </button>
            </ColumnSocialMidiaDescktop>
            <ColumnSocialMidiaDescktop target="_blanck" style= {{justifyContent: 'center'}}>
              <button type="button" style={{backgroundColor: '#15171a'}}>
              
              </button>
            </ColumnSocialMidiaDescktop>  
            <Container style={{ alignItems: 'center', justifyContent: 'center', padding: '20px 20px' }}>
              <div style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center', alignContent: "flex" , color: '#fff', fontSize: '22px'}}>
                <h3>Produtos Selecionados</h3>
              </div>
            </Container>

        </>

        : 
        <>
            <ColumnSocialMidiaDescktop target="_blanck" style= {{justifyContent: 'center'}}>
              <button type="button" style={{backgroundColor: '#15171a'}}>
              
              </button>
            </ColumnSocialMidiaDescktop>
            <ColumnSocialMidiaDescktop target="_blanck" style= {{justifyContent: 'center'}}>
              <button type="button" style={{backgroundColor: '#15171a'}}>
              
              </button>
            </ColumnSocialMidiaDescktop>  
        </>
        
        }
  
        <ColumnSocialMidiaDescktop target="_blanck" style= {{justifyContent: 'center'}}>
          <button type="button" style={{backgroundColor: '#15171a'}}>
           
          </button>
        </ColumnSocialMidiaDescktop>     
      </>



    </>

    
  );
}


/*



*/
