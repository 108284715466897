import styled from 'styled-components';

export const ContainerImagem = styled.div`
  //padding-top: 0.25rem;
  //padding-bottom: 0.30rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  padding: 0.25rem;

  h1{
    color: white;
  }
  span{
    color: white;  
  }
`;