import React from 'react';
import { SocialIcon } from 'react-social-icons';

function PaginaOfertas() {
  const linkGrupoWhatsApp = "https://chat.whatsapp.com/Kxra4l33KhZ0ITbLCzjiwj";

  return (
    <div style={{ backgroundColor: '#15171a', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <h1 style={{ color: '#fff', padding: '15px 30px', }}>Ofertas de Tecnologia</h1>
            <p></p>
            <a href={linkGrupoWhatsApp} className="botao" style={{ padding: '15px 30px', backgroundColor: '#25D366', color: '#fff', fontSize: '22px', borderRadius: '8px', textDecoration: 'none', cursor: 'pointer' }}>
                <SocialIcon network="whatsapp" bgColor="#0000" fgColor="white" style={{ height: 40, width: 40 }} />
                Ofertas do WhatsApp
            </a>
        </div>
      </div>
  );
}

export default PaginaOfertas;
