export enum TipoInformacaoEnum {
    LOJA = 1,
    CATEGORIA = 2,
    OFERTA = 3, 
    CUPONS = 4,    
    TAGS = 5,    
    IMAGEM = 6,    
    POSTS = 7,    
    PRODUTO = 8,    
}

