import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Recentes from './pages/Recentes';
import Oferta from './pages/Oferta';
import Search from './pages/Search';
import Categorias from './pages/Categorias';
import RecentesCategoria from './pages/RecentesCategoria';
import Privacidade from './pages/Privacidade';
import Termos from './pages/Termos';
import GrupoWatzap from './pages/GrupoWatsApp';
import GrupoTelegram from './pages/GrupoTelegram';

export default function Routes({search}) {

  return (
    <Switch>
      <Route path="/" exact component={Recentes} forceRefresh={true}/>
      <Route path="/recentes" exact component={Recentes}/>
      <Route path="/search/:description" exact component={Search} render={true} forceRefresh={true} />

     
      <Route path="/categorias" exact component={Categorias}/>

      <Route path="/promo/:short" exact component={Oferta} />
      <Route path="/oferta/:short" exact component={Oferta} />
      <Route path="/lk/:short" exact component={Oferta} />

      <Route path="/categoria/:uid" exact component={RecentesCategoria} />
      <Route path="/categoriaRecentes/:descricao" exact component={RecentesCategoria} />

      <Route path="/politica-de-privacidade" exact component={Privacidade} />
      <Route path="/termos-de-uso" exact component={Termos} />
      <Route path="/whatsapp" exact component={GrupoWatzap} />
      <Route path="/telegram" exact component={GrupoTelegram} />

      
    </Switch>
    
  );
}